import { available_modules } from '@service/UserService';
import { defineStore } from 'pinia';
import { useAppConfigStore } from '@/stores/AppConfigStore';
import { storeToRefs } from 'pinia';

export const useAuthStore = defineStore('auth', {

    state: () => ({
        isAuthenticated: true,
    }),

    actions: {
        setAuthenticated() {
            this.isAuthenticated = true;
        },
        setUnauthenticated() {
            this.isAuthenticated = false;
        },
        handle401Error() {
            this.setUnauthenticated();
        },

        hasModuleAvailable(path){
            // Casos especiales (powermarket 579)
            const appConfigStore = useAppConfigStore();
            const { currentPartnership } = storeToRefs(appConfigStore);

            if (path.includes('mailing')){
                return (currentPartnership?.value?.id == 579)
            }

            return false
        },

        async canPerformAction(action){
            const appConfigStore = useAppConfigStore();
            const partnership = await appConfigStore.loadCurrentPartnership()

            switch(action){
                case 'create_board':
                    return partnership.value?.current_subscription?.plan == 'enterprise'
                    break;
            }

            return true
        },
        
        async canAccessRoute(route){
            
            const appConfigStore = useAppConfigStore();

            const partnership = await appConfigStore.loadCurrentPartnership()
            const user = await appConfigStore.loadCurrentUser()

            // Gestionar permisos, solo para admins
            if ((route.path == '/administrar/usuariospropios/permisos') && (!user.value.admin))
                return false

            // Lógica modulos deshabilitados por Permisos
            const disabled_modules = available_modules.filter((am) => { return user.value.disabled_modules_ids?.includes(am.id) })
            if (disabled_modules.some(dm=> route.path.includes(dm.path)))
                return false

            // Lógica chatbot
            if (route.path.includes('chatbot')){
                return partnership.value?.chatbot_enabled
            }

            // Lógica por plan
            switch(partnership.value?.current_subscription?.plan){
                case 'starter':
                case 'novel':
                    return !route.meta.minimumPlan
                    break;
                case 'professional':
                    return (!route.meta.minimumPlan || route.meta.minimumPlan == 'professional')
                    break;
                case 'enterprise':
                    return true
                    break;
            }
            
            return true
        }
    },

});
