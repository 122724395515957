import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
//import 'prismjs/themes/prism-coy.css';
import './assets/styles/layout.scss';
//import './assets/demo/flags/flags.css';
//import './assets/fontawesome-free-6.2.0-web/css/all.min.css';
import './assets/lineawesome1.3.0/css/line-awesome.min.css';

import { default as CustomCalendar } from '@components/CustomCalendar.vue';
import { default as AutocompleteProducer } from '@components/shared/AutocompleteProducer.vue'
import { default as DataTableBase } from '@components/shared/DataTableBase.vue';
import { default as CurrencyInputNumber } from '@components/CurrencyInputNumber.vue';
import { default as PercentageInputNumber } from '@components/PercentageInputNumber.vue';
import { default as SaveCancelForm } from '@components/shared/SaveCancelForm.vue';
import { default as FiltersFieldset } from '@components/shared/FiltersFieldset.vue';
import { default as ButtonSearch } from '@components/shared/ButtonSearch.vue';
import { default as SearchSideBar } from '@components/shared/SearchSideBar.vue';
import { default as AutocompleteBase } from '@components/shared/AutocompleteBase.vue';
import { default as ErrorReportForm } from '@components/shared/importaciones/ErrorReportForm.vue';
import { default as DropdownPais } from '@components/shared/DropdownPais.vue';
import { default as ErrorReportStepper } from '@components/shared/importaciones/ErrorReportStepper.vue';
import { default as AutocompletePeopleGroup } from '@components/shared/AutocompletePeopleGroup.vue';
import { default as AutocompletePerson } from '@components/shared/AutocompletePerson.vue';
import { default as AvatarCompanies } from '@components/shared/AvatarCompanies.vue';
import FullCalendar from '@fullcalendar/vue3';
import { useAuthStore } from '@stores/AuthStore';
import Axios from 'axios';
import { createPinia } from 'pinia';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import AutoComplete from 'primevue/autocomplete';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import FloatLabel from 'primevue/floatlabel';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import BlockUI from 'primevue/blockui';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import ButtonGroup from 'primevue/buttongroup';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import Column from 'primevue/column';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import DynamicDialog from 'primevue/dynamicdialog';
import Editor from 'primevue/editor';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import InlineMessage from 'primevue/inlinemessage';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputIcon from 'primevue/inputicon';
import IconField from 'primevue/iconfield';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import StyleClass from 'primevue/styleclass';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ToggleButton from 'primevue/togglebutton';
import Toolbar from 'primevue/toolbar';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import TabMenu from 'primevue/tabmenu';
import TreeTable from 'primevue/treetable';
import SelectButton from 'primevue/selectbutton';
import { createApp, reactive, ref } from 'vue';
import App from './App.vue';
import i18n from './i18n';
import { getLocaleObject } from '@i18n/localeService';
import router from './router';

import { logSlackError } from '@service/AxiosBaseService';
import {useRouter} from "vue-router";
import VueGtag from 'vue-gtag';

/*


import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import ColorPicker from 'primevue/colorpicker';

import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import Knob from 'primevue/knob';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import PanelMenu from 'primevue/panelmenu';

import PickList from 'primevue/picklist';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';

import Slider from 'primevue/slider';

*/

/*
import Steps from 'primevue/steps';


import TieredMenu from 'primevue/tieredmenu';
import Timeline from 'primevue/timeline';


import TriStateCheckbox from 'primevue/tristatecheckbox';

import CodeHighlight from './AppCodeHighlight';
*/

export const pageLoading = ref(false);

router.beforeEach(async function (to, from) {
	window.scrollTo(0, 0);
	pageLoading.value = true;
	if (!auth.isAuthenticated) {
		pageLoading.value = false;
		auth.handle401Error();
	}
	const canAccess = await auth.canAccessRoute(to);
	if (!canAccess) {
		app.config.globalProperties.$toast.add({
			severity: 'error',
			summary: 'Error',
			detail: 'No puede acceder a esta sección',
			life: 3000,
		});
		return false;
		//return { name: 'dashboard' } // ir al inicio
	}
});

router.afterEach(() => {
	setTimeout(() => {
		pageLoading.value = false;
	}, 3000);
});

//console.log(import.meta.env);

if (!import.meta.env.VITE_BASE_URL.includes('localhost')) {
	Axios.defaults.baseURL = import.meta.env.VITE_BASE_URL;
	Axios.defaults.withCredentials = true;
} /*else {
    Axios.defaults.headers.common['Authorization'] = import.meta.env.VITE_ADMINSE_API_KEY;
}*/

//Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Axios.defaults.headers.common['Content-Type'] = 'application/json';
// Axios.interceptors.request.use((config) => {
// 	if (config.headers['FinanceData-API']) {
// 		config.baseURL = 'https://dolarapi.com/v1/';
// 	}
// 	return config;
// });
Axios.interceptors.response.use(
	(res) => {
		if (res) {
		}
		return res;
	},
	(err) => {
		if (err.response) {
			const statusCode = err.response.status;
			if (statusCode === 403) {
				app.config.globalProperties.$toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Acción no permitida',
					life: 3000,
				});
			} else if (statusCode === 401) {
				app.config.globalProperties.$toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'No autorizado',
					life: 3000,
				});
				auth.handle401Error();
			}
			throw err;
		} else {
			console.log('Error genérico sin manejar:', err);
			//logSlackError("Axios error: "+err)
			//auth.handle401Error();
		}
	}
);

const pinia = createPinia();
const app = createApp(App);

app.use(i18n);

//app.config.unwrapInjectedRef = true;

/*
useGet('/api/partnership').then((response) => {
    console.log(response);
    app.provide('partnership', response.data.partnership);
}).catch((error) => {
    this.$toast.add({ severity: "error", summary: "Error", detail: "Error buscando estrucura de trabajo", life: 3000 });
});
*/

document.documentElement.style.fontSize = '14px'; // se puede parametrizar
app.config.globalProperties.$appState = reactive({ theme: 'saga-blue', darkTheme: false });

app.config.errorHandler = (err, instance, info) => {
	console.log('Runtime error tracked:');
	console.log(err.stack);
	const path = window.location.hash
	logSlackError(`\n Runtime error logged in slack (hopefully) ${err.message} \n Path: ${path} \n Stack: ${err.stack}`);
};

app.use(PrimeVue, {
	ripple: true,
	inputStyle: 'outlined',
	locale: getLocaleObject(),
});

app.use(pinia);
const auth = useAuthStore();
app.use(ConfirmationService);
app.use(DialogService);
app.use(ToastService);
app.use(router);

app.use(
	VueGtag,
	{
		config: { id: 'G-RRNQLRKHZK' },
		params: {
			anonymize_ip: true,
		},
	},
	router
);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
//app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);

app.component('DataTableBase', DataTableBase);
app.component('CurrencyInputNumber', CurrencyInputNumber);
app.component('PercentageInputNumber', PercentageInputNumber);
app.component('SaveCancelForm', SaveCancelForm);
app.component('FiltersFieldset', FiltersFieldset);
app.component('ErrorReportForm', ErrorReportForm);
app.component('DropdownPais', DropdownPais);
app.component('ErrorReportStepper', ErrorReportStepper);    
app.component('ButtonSearch', ButtonSearch);
app.component('SearchSideBar', SearchSideBar);
app.component('AutocompleteBase', AutocompleteBase);
app.component('AutocompletePeopleGroup', AutocompletePeopleGroup);
app.component('AvatarCompanies', AvatarCompanies);
app.component('AutocompletePerson', AutocompletePerson);
app.component('CustomCalendar', CustomCalendar);
app.component('AutocompleteProducer', AutocompleteProducer);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('ButtonGroup', ButtonGroup);
app.component('Calendar', Calendar);
app.component('Card', Card);
//app.component('Carousel', Carousel);
//app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
//app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('DynamicDialog', DynamicDialog);
//app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
//app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Editor', Editor);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('InlineMessage', InlineMessage);
//app.component('Inplace', Inplace);
//app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('InputIcon', InputIcon);
app.component('IconField', IconField);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
//app.component('Galleria', Galleria);
//app.component('Knob', Knob);
//app.component('Listbox', Listbox);
//app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('FloatLabel', FloatLabel)
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
//app.component('OrderList', OrderList);
//app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
//app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
//app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
//app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
//app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
//app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
//app.component('TieredMenu', TieredMenu);
//app.component('Timeline', Timeline);
app.component('BlockUI', BlockUI);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeTable', TreeTable);
//app.component('TriStateCheckbox', TriStateCheckbox);
app.component('FullCalendar', FullCalendar);

app.mount('#app');
